<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Taxonomies">
      <validation-observer ref="simpleRules">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <!-- Row Loop -->
              <b-row>
                <!-- Taxonomy Reapeted -->
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Locale"
                    rules="required"
                  >
                    <b-form-group
                      label="Locale"
                      label-for="active"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="locale"
                        disabled
                        v-model="languageSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="languageOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Term"
                    rules="required"
                  >
                    <b-form-group
                      label="Term"
                      label-for="term"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="term"
                        v-model="termSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="terms"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="taxonomy name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="itemData.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="taxonomy description"
                      rules="required"
                    >
                      <b-form-input
                        id="texonomyDescription"
                        v-model="itemData.description"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <!-- add new button -->
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'

import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      taxID: 0,
      taxSlug: '',
      languageSelected: {
        text: '',
        Value: '',
      },
      languageOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      terms: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      termSelected: {
        text: '',
        value: '',
      },
      itemData: {
        locale: '',
        term_id: '',
        name: '',
        description: '',
      },
      required,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.taxID = this.$route.query.item.pivot.term_id
    this.taxSlug = this.$route.query.item.pivot.slug
    if (this.$route.query.item.locale == 'en') {
      this.languageSelected.text = 'English'
      this.languageSelected.value = 'en'

      this.fetchTerms(this.$route.query.item.locale)
    } else {
      this.languageSelected.text = 'Arabic'
      this.languageSelected.value = 'ar'

      this.fetchTerms(this.$route.query.item.locale)
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.itemData.locale = this.languageSelected.value
          this.itemData.term_id = this.termSelected.value

          axios
            .put(
              'items-guide/' +
                this.id +
                '/taxonomy/' +
                this.taxID +
                '/' +
                this.taxSlug +
                '/update',
              this.itemData
            )
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })

              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    fetchTerms(lang) {
      axios
        .get('terms/' + lang)
        .then((result) => {
          const data = result.data.data
          for (let index in data) {
            if (data[index].translation != null) {
              this.terms.push({
                text: data[index].translation.name,
                value: data[index].id.toString(),
              })
            } else {
              this.terms.push({
                text: 'undefinded',
                value: data[index].id.toString(),
              })
            }
          }
          this.fetchTax()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    fetchTax() {
      axios
        .get(
          'items-guide/' +
            this.id +
            '/taxonomy/' +
            this.taxID +
            '/' +
            this.taxSlug +
            '/show'
        )
        .then((result) => {
          const data = result.data.data
          for (let index in this.terms) {
            if (this.terms[index].value == data[0].term_id.toString()) {
              this.termSelected.value = data[0].term_id
              this.termSelected.text = this.terms[index].text
            }
          }
          this.itemData.name = data[0].name
          this.itemData.description = data[0].description
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
 
.repeater-form {
  transition: 0.35s height;
}
</style>